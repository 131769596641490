html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}
div#container {
  width: 100%;
  height: 100%;
}

svg {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
}
svg .week-tick {
  stroke: black;
}

svg .day-hand {
  /* stroke-width: 8; */
  stroke: black;
  stroke-linecap: round;
}
svg .guide {
  display: none;
}
